const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\NotFoundRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";

import { ErrorMessage } from "./ErrorMessage";

export const NotFoundRoute = observer(function NotFoundRoute() {
  return (
    React.createElement(ErrorMessage, {
      icon: "fa:exclamation-circle",
      title: "Seite nicht gefunden"  ,
      message: "Die Seite konnte nicht gefunden werden"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    )
  );
});
