const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataCreateRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumb, Divider, Typography } from "antd";

import { useApp } from "../context";
import { Link, useParams } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { DataForm } from "./DataForm";

export const DataCreateRoute = observer(function DataCreateRoute() {
  const app = useApp();

  const { typeName, data } = useParams();

  const type = app.getType(typeName);
  const meta = _optionalChain([type, 'optionalAccess', _ => _.getMeta, 'call', _2 => _2()]);

  const dataDec = React.useMemo(
    () => JSON.parse(decodeURIComponent(data || "{}")),
    [data]
  );

  if (!type || !meta) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Seite nicht gefunden"  ,
        message: `Daten Typ '${typeName}' wurde nicht gefunden`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      )
    );
  }

  const fields = Object.values(meta.fields).filter((field) => field.mandatory);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Breadcrumb, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , React.createElement(Link, { to: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, "Home")
        )
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          , React.createElement(Link, {
            to: `/data/list/${type.typeName}`,
            children: _optionalChain([type, 'access', _3 => _3.getMeta, 'call', _4 => _4(), 'optionalAccess', _5 => _5.label]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          )
        )
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, "Neu Anlegen" )
      )

      , React.createElement(Typography.Title, { level: 2, children: "Neu Anlegen: " + meta.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )

      , React.createElement(DataForm, { type: typeName, fields: fields, data: dataDec, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )
    )
  );
});
