 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import md5 from "crypto-js/md5";

































/**
 * Small API wrapper
 */
export class ApiService {
  

   __init() {this.secure = true}
   __init2() {this.host = "crm.pollrich.com/vtigercrm"}

   __init3() {this.session = ""}

  constructor(app) {;ApiService.prototype.__init.call(this);ApiService.prototype.__init2.call(this);ApiService.prototype.__init3.call(this);
    this.app = app;
  }

   get username() {
    return window.localStorage.getItem("pollrich/username");
  }

   set username(value) {
    if (value) {
      window.localStorage.setItem("pollrich/username", value);
    } else {
      window.localStorage.removeItem("pollrich/username");
    }
  }

   get accessToken() {
    return window.localStorage.getItem("pollrich/accessToken");
  }

   set accessToken(value) {
    if (value) {
      window.localStorage.setItem("pollrich/accessToken", value);
    } else {
      window.localStorage.removeItem("pollrich/accessToken");
    }
  }

   get userId() {
    return window.localStorage.getItem("pollrich/userId");
  }

   set userId(value) {
    if (value) {
      window.localStorage.setItem("pollrich/userId", value);
    } else {
      window.localStorage.removeItem("pollrich/userId");
    }
  }

   async getSession() {
    if (!this.session) {
      const challange = await this.challange();
      const session = await this.login(challange.token);

      this.session = session.sessionName;
      this.userId = session.userId;
    }
  }

   async challange() {
    return await this.fetch("get", {
      operation: "getchallenge",
      username: this.username || "",
    });
  }

   async login(challangeToken) {
    return await this.fetch("post", {
      operation: "login",
      username: this.username || "",
      accessKey: md5(challangeToken + this.accessToken).toString(),
    });
  }

   async logout() {
    return await this.fetch("post", {
      operation: "logout",
      sessionName: this.session,
    });
  }

   async fields(type) {
    return await this.fetch("get", {
      operation: "fields",
      sessionName: this.session,
      elementType: type,
    });
  }

   async listtypes() {
    return await this.fetch("get", {
      operation: "listtypes",
      sessionName: this.session,
    });
  }

   async describe(type) {
    return await this.fetch("get", {
      operation: "describe",
      sessionName: this.session,
      elementType: type,
    });
  }

   async query(query) {
    return await this.fetch("get", {
      operation: "query",
      sessionName: this.session,
      query: query,
    });
  }

   async queryType(type, page) {
    return await this.query(`SELECT * FROM ${type} LIMIT ${page * 100}, 100;`);
  }

   async retrieve(id) {
    return await this.fetch("get", {
      operation: "retrieve",
      sessionName: this.session,
      id: id,
    });
  }

   async create(type, data) {
    return await this.fetch("post", {
      operation: "create",
      sessionName: this.session,
      elementType: type,
      element: JSON.stringify(data),
    });
  }

   async update(data) {
    return await this.fetch("post", {
      operation: "update",
      sessionName: this.session,
      element: JSON.stringify(data),
    });
  }

   async delete(id) {
    return await this.fetch("post", {
      operation: "delete",
      sessionName: this.session,
      id: id,
    });
  }

   async sync(type, lastSync = 0) {
    return await this.fetch("get", {
      operation: "sync",
      sessionName: this.session,
      elementType: type,
      modifiedTime: (lastSync || 0).toString(),
    });
  }

   async fetch(
    method,
    params
  ) {
    try {
      const s = this.secure ? "s" : "";

      const qs = method === "get" ? this.createQueryString(params) : "";
      const body = method === "post" ? this.createFormData(params) : undefined;

      const url =
        params.operation === "fields"
          ? `http${s}://${this.host}/webservice_mobile.php?${qs}`
          : `http${s}://${this.host}/webservice.php?${qs}`;

      // console.log(
      //   `[${method}]`,
      //   url,
      //   method === "post" ? JSON.stringify(params, null, 2) : ""
      // );

      const response = await fetch(url, {
        method,
        body,
      });

      try {
        const json = await response.json();

        if (!json.success || !json.result) {
          throw new ApiError(json);
        }

        return json.result;
      } catch (error) {
        console.error(error);

        if (error.__api__error) {
          throw error;
        }

        throw new ApiError();
      }
    } catch (error) {
      if (error.__api__error) {
        throw error;
      }

      console.error(error);

      throw new NetworkError();
    }
  }

   createQueryString(params) {
    const qs = new URLSearchParams();

    for (const [key, value] of Object.entries(params)) {
      qs.set(key, value);
    }

    return qs.toString();
  }

   createFormData(params) {
    const body = new FormData();

    for (const [key, value] of Object.entries(params)) {
      body.append(key, value);
    }

    return body;
  }
}

class NetworkError extends Error {
  __init4() {this.__network__error = true}

  constructor() {
    super("Network Error");NetworkError.prototype.__init4.call(this);;
  }
}

class ApiError extends Error {
  __init5() {this.__api__error = true}
  
  

  constructor(vTigerResponse = null) {
    super(
      "vTiger API Response Error " +
        (vTigerResponse
          ? "= " + JSON.stringify(vTigerResponse, null, 2)
          : "(Unknown)")
    );ApiError.prototype.__init5.call(this);;

    if (_optionalChain([vTigerResponse, 'optionalAccess', _ => _.error, 'optionalAccess', _2 => _2.code])) {
      this.__api__error__code = _optionalChain([vTigerResponse, 'optionalAccess', _3 => _3.error, 'optionalAccess', _4 => _4.code]);
    } else {
      this.__api__error__code = undefined;
    }

    if (_optionalChain([vTigerResponse, 'optionalAccess', _5 => _5.error, 'optionalAccess', _6 => _6.message])) {
      this.__api__error__message = _optionalChain([vTigerResponse, 'optionalAccess', _7 => _7.error, 'optionalAccess', _8 => _8.message]);
    } else {
      this.__api__error__message = undefined;
    }
  }
}
