const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataFormInput.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";

import moment from "moment";
import {
  Input,
  InputNumber,
  Select,
  Checkbox,
  TimePicker,
  DatePicker,
} from "antd";

import { useApp } from "../context";

import { VTIGER_DATE_FORMAT, VTIGER_TIME_FORMAT } from "../const";

export const DataFormInput = observer(function DataFormInput({
  field,
  value,
  setValue,
}



) {
  const app = useApp();
  const type = field.type;

  switch (type.name) {
    case "string":
    case "phone":
    case "url":
    case "email":
    case "currency":
      return (
        React.createElement(Input, {
          disabled: !field.editable,
          value: value,
          onChange: (e) => {
            setValue(e.target.value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        )
      );

    case "text":
      return (
        React.createElement(Input.TextArea, {
          disabled: !field.editable,
          value: value,
          onChange: (e) => {
            setValue(e.target.value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        )
      );

    case "integer":
      return (
        React.createElement(InputNumber, {
          disabled: !field.editable,
          value: parseInt(value) || 0,
          onChange: (v) => {
            setValue(v ? _optionalChain([v, 'optionalAccess', _ => _.toString, 'call', _2 => _2()]) : "");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
        )
      );

    case "boolean":
      return (
        React.createElement(Checkbox, {
          disabled: !field.editable,
          checked: value == "1",
          onChange: (e) => {
            setValue(e.target.checked ? "1" : "0");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        )
      );

    case "picklist":
      return (
        React.createElement(Select, {
          disabled: !field.editable,
          value: value,
          onChange: (v) => setValue(v), __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        
          , type.picklistValues.map(({ label, value }, index) => (
            React.createElement(Select.Option, { key: index, value: value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
              , label
            )
          ))
        )
      );

    case "multipicklist":
      return (
        React.createElement(Select, {
          mode: "multiple",
          disabled: !field.editable,
          value: value ? value.split(" |##| ") : undefined,
          onChange: (v) => {
            setValue(v.join(" |##| "));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        
          , type.picklistValues.map(({ label, value }, index) => (
            React.createElement(Select.Option, { key: index, value: value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
              , label
            )
          ))
        )
      );

    case "owner":
      return (
        React.createElement(Select, {
          value: value,
          onChange: (nextValue) => {
            setValue(nextValue);
          },
          children: app.types.Users.options.map((option) => (
            React.createElement(Select.Option, {
              key: option.value,
              value: option.value,
              children: option.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
            )
          )), __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        )
      );

    case "reference":
      // const entities = [];

      // for (const refersTo of type.refersTo) {
      //   const t = app.getType(refersTo);

      //   if (t) {
      //     for (const entity of t.listEntities()) {
      //       entities.push({
      //         value: entity.id,
      //         label: t.getTitle(entity),
      //       });
      //     }
      //   }
      // }

      const options = type.refersTo
        .map((refersTo) => _optionalChain([app, 'access', _3 => _3.getType, 'call', _4 => _4(refersTo), 'optionalAccess', _5 => _5.options]))
        .filter(Boolean)
        .flat();

      return (
        React.createElement(Select, {
          showSearch: true,
          value: value,
          onChange: (nextValue) => {
            setValue(nextValue);
          },
          optionFilterProp: "children",
          filterOption: (input, option) =>
            // @ts-ignore
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          ,
          children: options.map((entity) =>
            !entity ? null : (
              React.createElement(Select.Option, {
                key: entity.value,
                value: entity.value,
                children: entity.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
              )
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
        )
      );

    case "date":
      // if (type.format !== "dd-mm-yyyy") {
      //   return <span>Unbekanntes Format, bitte an Administrator wenden.</span>;
      // }

      return (
        React.createElement(DatePicker, {
          value: value ? moment(value, VTIGER_DATE_FORMAT) : undefined,
          format: VTIGER_DATE_FORMAT,
          onChange: (date) => {
            setValue(_optionalChain([date, 'optionalAccess', _6 => _6.format, 'call', _7 => _7(VTIGER_DATE_FORMAT)]) || "");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
        )
      );

    case "time":
      const displayFormat = "HH:mm";

      return (
        React.createElement(TimePicker, {
          value: value ? moment(value, VTIGER_TIME_FORMAT) : undefined,
          format: displayFormat,
          minuteStep: 5,
          onChange: (date) => {
            setValue(_optionalChain([date, 'optionalAccess', _8 => _8.format, 'call', _9 => _9(VTIGER_TIME_FORMAT)]) || "");
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}
        )
      );

    // case "datetime":
    //   return <span>{value}</span>;

    default:
      return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}, "Unbekanntes Format, bitte an Administrator wenden."     );
      return React.createElement('pre', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}, JSON.stringify(field, null, 2));
  }
});
