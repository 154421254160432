const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\HomeRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { Avatar, Divider, List, } from "antd";

import { useApp } from "../context";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { Icon } from "@opendash/icons";

export const HomeRoute = observer(function HomeRoute() {
  const app = useApp();
  const navigate = useNavigate();

  if (app.error) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Unbekannter Fehler" ,
        message: "Die Anwendung wurde nicht korrekt Synchronisiert"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      /* <Typography.Title level={2} children={"Pollrich CRM"} /> */

      , React.createElement(List, {
        itemLayout: "horizontal",
        dataSource: Object.entries(app.types),
        renderItem: ([typeName, type]) => (
          React.createElement(Link, { to: `/data/list/${typeName}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
            , React.createElement(List.Item, { actions: [React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
              , React.createElement(List.Item.Meta, {
                avatar: React.createElement(Avatar, { icon: React.createElement(Icon, { icon: type.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} ),
                // avatar={<Icon icon={type.icon} />}
                title: _optionalChain([type, 'access', _ => _.getMeta, 'call', _2 => _2(), 'optionalAccess', _3 => _3.label]),
                description: `${type.count} Einträge`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
              )
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} )

      , React.createElement(List, {
        itemLayout: "horizontal",
        dataSource: app.ui.menuItems,
        renderItem: (item) => (
          React.createElement(Link, { to: item.getUrl(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , React.createElement(List.Item, { actions: [React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
              , React.createElement(List.Item.Meta, {
                avatar: React.createElement(Avatar, { icon: React.createElement(Icon, { icon: item.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} ),
                title: item.label,
                description: item.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
              )
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      )
    )
  );
});
