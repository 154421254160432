import { message as msg } from "antd";




export class NotificationService {
  

  constructor(app) {
    // makeAutoObservable(this);

    this.app = app;
  }

  success(message) {
    msg.success(message);
  }

  error(message) {
    msg.error(message);
  }

  warning(message) {
    msg.warning(message);
  }

  info(message) {
    msg.info(message);
  }
}
