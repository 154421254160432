const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataDisplay.tsx";import React from "react";
import { observer } from "mobx-react-lite";


import { Checkbox } from "antd";
import { useApp } from "../context";
import { Link } from "react-router-dom";

export const DataDisplay = observer(function DataDisplay({
  field,
  value,
}


) {
  const app = useApp();
  const type = field.type;

  if (!value) {
    return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, "-");
  }

  switch (type.name) {
    case "string":
    case "currency":
    case "text":
    case "picklist":
    case "integer":
    case "datetime":
    case "time":
    case "date":
      return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, value);

    case "multipicklist":
      return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, (value || "").split(" |##| ").join(", "));

    case "url":
      return (
        React.createElement('a', { href: value.startsWith("http") ? value : `http://${value}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          , value
        )
      );

    case "email":
      return React.createElement('a', { href: `mailto:${value}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, value);

    case "phone":
      return React.createElement('a', { href: `tel:${value}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, value);

    case "boolean":
      return React.createElement(Checkbox, { checked: value == "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} );

    case "owner":
    case "reference":
      // return <pre>{JSON.stringify({ value, field }, null, 2)}</pre>;

      const dataType = app.getTypeForEntity(value);

      if (!dataType) {
        return (
          React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "Diese Referenz wird nicht unterstützt. Bitte vTiger benutzen."

          )
        );
      }

      const obj = dataType.getEntity(value);

      if (!obj) {
        return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "Referenz nicht gefunden."  );
      }

      const link = `/data/view/${dataType.typeName}/${value}`;
      const label = dataType.getTitle(obj);

      if (!label) {
        return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}, "Referenz nicht gefunden."  );
      }

      return React.createElement(Link, { to: link, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}, label);

    default:
      return (
        React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}, "Dieses Feld wird leider nicht unterstützt. Bitte vTiger benutzen."

        )
      );
  }
});
