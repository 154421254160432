const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\Router.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Routes, Route } from "react-router-dom";

import { HomeRoute } from "./HomeRoute";

import { DataListRoute } from "./DataListRoute";
import { DataViewRoute } from "./DataViewRoute";
import { DataCreateRoute } from "./DataCreateRoute";
import { DataFormRoute } from "./DataFormRoute";

import { LoginRoute } from "./LoginRoute";
import { LogoutRoute } from "./LogoutRoute";

import { NotFoundRoute } from "./NotFoundRoute";

export const Router = observer(function Router() {
  const app = useApp();

  if (!app.authentificated) {
    return React.createElement(LoginRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} );
  }

  return (
    React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      , React.createElement(Route, { path: "login", element: React.createElement(LoginRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
      , React.createElement(Route, { path: "logout", element: React.createElement(LogoutRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )

      , React.createElement(Route, { path: "", element: React.createElement(HomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )
      , React.createElement(Route, { path: "home", element: React.createElement(HomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )

      , React.createElement(Route, { path: "data/list/:typeName", element: React.createElement(DataListRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
      , React.createElement(Route, {
        path: "data/list/:typeName/:filterAttribute/:filterValue",
        element: React.createElement(DataListRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      )
      , React.createElement(Route, { path: "data/view/:typeName/:id", element: React.createElement(DataViewRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
      , React.createElement(Route, {
        path: "data/view/:typeName/:id/:block",
        element: React.createElement(DataViewRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      )
      , React.createElement(Route, {
        path: "data/view/:typeName/:id/:block/:action",
        element: React.createElement(DataViewRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )

      , React.createElement(Route, { path: "data/create/:typeName", element: React.createElement(DataCreateRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
      , React.createElement(Route, { path: "data/create/:typeName/:data", element: React.createElement(DataCreateRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
      , React.createElement(Route, { path: "data/form/:data", element: React.createElement(DataFormRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )

      , React.createElement(Route, { path: "*", element: React.createElement(NotFoundRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
    )
  );
});
