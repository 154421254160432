const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\Layout.tsx";import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppBanner } from "./AppBanner";
import { Navigation } from "./Navigation";

const Header = styled.div`
  height: var(--header-height);
  width: 100%;

  background: white;
  border-bottom: 1px solid #ebebeb;
  user-select: none;

  overflow: hidden;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 2;
`;

const HeaderInner = styled.div`
  height: var(--header-height);
`;

const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
`;

const LogoHolder = styled.div`
  cursor: pointer;

  display: block;
  float: left;

  height: var(--header-height);
  margin-right: 15px;

  img {
    height: var(--header-height);
  }
`;

const HeaderNav = styled.nav`
  display: block;
  float: right;

  height: var(--header-height);
  line-height: var(--header-height);

  letter-spacing: 0.5px;
  font-weight: 700;
  font-size: 13px;

  a {
    display: inline-block;
    height: var(--header-height);

    color: var(--color-default-font);
    text-decoration: none;

    padding: 0 15px;

    &:hover {
      color: var(--color-primary);
    }
  }
`;

const Content = styled.div`
  padding-top: calc(20px + var(--header-height));
  padding-bottom: 20px;

  position: relative;

  z-index: 1;
`;

export const Layout = React.memo(function Layout({ children }) {
  const navigate = useNavigate();
  return (
    React.createElement(React.Fragment, null
      , React.createElement(Header, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
          , React.createElement(HeaderInner, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
            , React.createElement(LogoHolder, { onClick: () => navigate("home"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
              , React.createElement('img', {
                src: require("../assets/logo/pollrich-logo.png"),
                alt: "Pollrich", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
              )
            )
            , React.createElement(HeaderNav, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
              , React.createElement(Navigation, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} )
            )
          )
        )
      )
      , React.createElement(Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        , React.createElement(AppBanner, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}} )
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}, children)
      )
    )
  );
});
