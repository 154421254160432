const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataListRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumb, Divider, Input, List, Typography } from "antd";

import { useApp } from "../context";
import { Icon } from "@opendash/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";

export const DataListRoute = observer(function DataListRoute() {
  const app = useApp();
  const navigate = useNavigate();

  const { typeName, filterAttribute, filterValue } = useParams();

  const [page, setPage] = React.useState(1);
  const [searchString, setSearchString] = React.useState("");

  const type = app.getType(typeName);

  if (!type) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Seite nicht gefunden"  ,
        message: `Daten Typ '${typeName}' wurde nicht gefunden`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      )
    );
  }

  let data = type.listEntities();

  if (filterAttribute && filterValue) {
    data = data.filter((row) => row[filterAttribute] === filterValue);
  }

  let rows = data.map((row) => ({
    id: row.id,
    title: type.getTitle(row),
    description: type.getDescription(row),
  }));

  if (searchString) {
    rows = rows.filter(
      (row) =>
        _optionalChain([row, 'access', _ => _.title, 'optionalAccess', _2 => _2.toString, 'call', _3 => _3(), 'access', _4 => _4.toLocaleLowerCase, 'call', _5 => _5(), 'access', _6 => _6.includes, 'call', _7 => _7(searchString)]) ||
        _optionalChain([row, 'access', _8 => _8.description, 'optionalAccess', _9 => _9.toString, 'call', _10 => _10(), 'access', _11 => _11.toLocaleLowerCase, 'call', _12 => _12(), 'access', _13 => _13.includes, 'call', _14 => _14(searchString)])
    );
  }

  const rowCount = rows.length || 0;
  const pageSize = 10;

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Breadcrumb, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          , React.createElement(Link, { to: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "Home")
        )
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}, _optionalChain([type, 'access', _15 => _15.getMeta, 'call', _16 => _16(), 'optionalAccess', _17 => _17.label]))
      )

      , React.createElement(Typography.Title, { level: 2, children: _optionalChain([type, 'access', _18 => _18.getMeta, 'call', _19 => _19(), 'optionalAccess', _20 => _20.label]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}} )

      , React.createElement(Input.Search, {
        placeholder: "Hier Suchbegriff eingeben.."  ,
        style: { width: "100%" },
        onSearch: (value) => {
          setSearchString(value.toLocaleLowerCase());
        },
        onChange: (e) => {
          const value = e.target.value;

          if (!value) {
            setSearchString("");
          }
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} )

      , React.createElement(List, {
        dataSource: rows,
        rowKey: "id",
        pagination: {
          position: "bottom",
          size: "small",
          current: page,
          total: rowCount,
          pageSize,
          showSizeChanger: false,
          onChange: (p) => {
            setPage(p);
          },
        },
        // pagination={false}
        renderItem: (row) => (
          React.createElement(Link, { to: `/data/view/${typeName}/${row.id}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
            , React.createElement(List.Item, { actions: [React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
              , React.createElement(List.Item.Meta, { title: row.title, description: row.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}} )
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
      )
    )
  );
});
