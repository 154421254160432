const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\AppLoadingScreen.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import styled from "styled-components";
import { Spin } from "antd";

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const AppLoadingScreen = observer(function AppLoadingScreen({
  children,
}) {
  const app = useApp();

  if (app.loading) {
    return (
      React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        , React.createElement(Spin, { size: "default", tip: "Die Anwendung lädt.."  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
      )
    );
  }

  return React.createElement(React.Fragment, null, children);
});
