const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataViewRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import {
  Breadcrumb,
  Button,
  Descriptions,
  Divider,
  List,
  Typography,
} from "antd";

import { useApp } from "../context";
import { Link, useParams } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { Icon } from "@opendash/icons";
import { DataForm } from "./DataForm";
import { DataDisplay } from "./DataDisplay";

export const DataViewRoute = observer(function DataViewRoute() {
  const app = useApp();

  const { typeName, id, block, action } = useParams();

  const type = app.getType(typeName);
  const meta = _optionalChain([type, 'optionalAccess', _ => _.getMeta, 'call', _2 => _2()]);

  if (!type || !meta) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Seite nicht gefunden"  ,
        message: `Daten Typ '${typeName}' wurde nicht gefunden`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      )
    );
  }

  const data = type.getEntity(id);

  if (!data) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Seite nicht gefunden"  ,
        message: `Object mit ID '${id}' wurde nicht gefunden`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
    );
  }

  // const isEditMode = !!block;
  const isEditMode = action === "edit";

  const fields = Object.values(meta.fields).filter((field) => {
    if (block && isEditMode) {
      return field.blockname === block && field.editable;
    } else if (block) {
      return field.blockname === block;
    } else {
      return field.summaryfield;
    }
  });

  // return <pre>{JSON.stringify(info.fields, null, 2)}</pre>;

  const breadcrumb = (
    React.createElement(Breadcrumb, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        , React.createElement(Link, { to: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, "Home")
      )

      , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        , React.createElement(Link, { to: `/data/list/${typeName}`, children: _optionalChain([type, 'access', _3 => _3.getMeta, 'call', _4 => _4(), 'optionalAccess', _5 => _5.label]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} )
      )

      , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        , React.createElement(Link, {
          to: `/data/view/${typeName}/${id}`,
          children: type.getTitle(data), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        )
      )

      , isEditMode && (
        React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
          , React.createElement(Link, { to: `/data/view/${typeName}/${id}/${block}`, children: block, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )
        )
      )

      , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
        , isEditMode ? "Bearbeiten" : block || "Übersicht"
      )
    )
  );

  if (isEditMode) {
    return (
      React.createElement(React.Fragment, null
        , breadcrumb

        , React.createElement(Typography.Title, { level: 2, children: type.getTitle(data), __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}} )

        , React.createElement(Typography.Title, { level: 3, children: block || "Übersicht", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} )

        , React.createElement(DataForm, { type: typeName, id: id, fields: fields, data: data, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
      )
    );
  }

  const blocks = meta.blocks
    .map((block) => {
      return {
        ...block,
        fieldCount: Object.values(meta.fields).filter(
          (field) => field.blockname === block.name
        ).length,
      };
    })
    .filter((block) => block.fieldCount > 0);

  return (
    React.createElement(React.Fragment, null
      , breadcrumb

      , block && (
        React.createElement(Link, { to: `/data/view/${typeName}/${id}/${block}/edit`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
          , React.createElement(Button, {
            icon: React.createElement(Icon, { icon: "fa:pen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} ),
            type: "primary",
            children: "Edit",
            style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
          )
        )
      )

      , React.createElement(Typography.Title, { level: 2, children: type.getTitle(data), __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} )

      , fields.length > 0 && (
        React.createElement(Descriptions, { title: block || "Übersicht", column: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
          , fields.map((field) => (
            React.createElement(Descriptions.Item, { key: field.name, label: field.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
              , React.createElement(DataDisplay, { field: field, value: data[field.name], __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}} )
            )
          ))
        )
      )

      , !block && Array.isArray(type.links) && type.links.length > 0 && (
        React.createElement(React.Fragment, null
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 149}} )

          , React.createElement(Typography.Title, { level: 3, children: "Aktionen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}} )

          , React.createElement(List, {
            dataSource: type.links,
            rowKey: "id",
            pagination: false,
            renderItem: (link) => (
              React.createElement(Link, { to: link.getUrl(id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
                , React.createElement(List.Item, { actions: [React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
                  , React.createElement(List.Item.Meta, {
                    title: link.title,
                    description: link.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
                  )
                )
              )
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
          )
        )
      )

      , !block && (
        React.createElement(React.Fragment, null
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 173}} )

          , React.createElement(Typography.Title, { level: 3, children: "Mehr Informationen" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}} )

          , React.createElement(List, {
            dataSource: blocks,
            rowKey: "id",
            pagination: false,
            renderItem: (block) => (
              React.createElement(Link, { to: `/data/view/${typeName}/${id}/${block.name}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
                , React.createElement(List.Item, { actions: [React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}} )], __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
                  , React.createElement(List.Item.Meta, {
                    title: block.name,
                    description: `${block.fieldCount} ${
                      block.fieldCount === 1 ? "Feld" : "Felder"
                    }`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
                  )
                )
              )
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
          )
        )
      )
    )
  );
});
