const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataFormRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { Breadcrumb, Divider, Typography } from "antd";

import { useApp } from "../context";
import { Link, useParams } from "react-router-dom";
import { ErrorMessage } from "./ErrorMessage";
import { DataForm } from "./DataForm";



export const DataFormRoute = observer(function DataFormRoute() {
  const app = useApp();

  const { data } = useParams();

  const {
    title,
    typeName,
    fieldNames,
    defaultData,
  } = React.useMemo(
    () => JSON.parse(decodeURIComponent(data || "{}")),
    [data]
  );

  const type = app.getType(typeName);
  const meta = _optionalChain([type, 'optionalAccess', _ => _.getMeta, 'call', _2 => _2()]);

  const fields = React.useMemo(() => {
    const fields = [];

    if (_optionalChain([meta, 'optionalAccess', _3 => _3.fields])) {
      for (const fieldName of fieldNames) {
        const field = meta.fields[fieldName];

        if (field) {
          fields.push(field);
        }
      }

      for (const field of Object.values(meta.fields)) {
        if (
          !fieldNames.includes(field.name) &&
          field.mandatory &&
          !(field.name in defaultData)
        ) {
          fields.push(field);
        }
      }
    }

    return fields;
  }, [meta, fieldNames]);

  if (!type || !meta) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Seite nicht gefunden"  ,
        message: `Daten Typ '${typeName}' wurde nicht gefunden`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Breadcrumb, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
          , React.createElement(Link, { to: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "Home")
        )
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
          , React.createElement(Link, {
            to: `/data/list/${type.typeName}`,
            children: _optionalChain([type, 'access', _4 => _4.getMeta, 'call', _5 => _5(), 'optionalAccess', _6 => _6.label]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
          )
        )
        , React.createElement(Breadcrumb.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}, title)
      )

      , React.createElement(Typography.Title, { level: 2, children: title, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )

      , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )

      , React.createElement(DataForm, { type: typeName, fields: fields, data: defaultData, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
    )
  );
});
