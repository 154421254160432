import { makeAutoObservable } from "mobx";
import moment from "moment";
import {
  FIELDS_ANRUF,
  FIELDS_BESUCH,
  VTIGER_DATE_FORMAT,
  VTIGER_TIME_FORMAT,
} from "../const";
import { createDataFormUrl } from "../helper/createDataFormUrl";



export class UiService {
  

  __init() {this.menu = false}

  constructor(app) {;UiService.prototype.__init.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

  get menuItems() {
    return [
      {
        icon: "fa:user",
        label: "Mein Profil anzeigen",
        description: "Hier klicken, um zum eigenen Profil zu kommen",
        getUrl: () => {
          const uid = this.app.api.userId;

          return `/data/view/Users/${uid}`;
        },
      },
      {
        icon: "fa:phone-alt",
        label: "Anruf anlegen",
        description: "Hier klicken, um einen neuen Anruf zu vermerken",
        getUrl: () =>
          createDataFormUrl({
            title: "Anruf anlegen",
            typeName: "Events",
            fieldNames: FIELDS_ANRUF,
            defaultData: {
              activitytype: "Call",
              assigned_user_id: this.app.api.userId ,
              duration_hours: "1",
              date_start: moment().format(VTIGER_DATE_FORMAT),
              due_date: moment().format(VTIGER_DATE_FORMAT),
              time_start: moment()
                .subtract(1, "hour")
                .format(VTIGER_TIME_FORMAT),
              time_end: moment().format(VTIGER_TIME_FORMAT),
            },
          }),
      },
      {
        icon: "fa:handshake-alt",
        label: "Kundenbesuch anlegen",
        description: "Hier klicken, um einen neuen Kundenbesuch zu vermerken",
        getUrl: () =>
          createDataFormUrl({
            title: "Kundenbesuch anlegen",
            typeName: "Events",
            fieldNames: FIELDS_BESUCH,
            defaultData: {
              activitytype: "Kundenbesuch",
              assigned_user_id: this.app.api.userId ,
              duration_hours: "1",
              date_start: moment().format(VTIGER_DATE_FORMAT),
              due_date: moment().format(VTIGER_DATE_FORMAT),
              time_start: moment()
                .subtract(1, "hour")
                .format(VTIGER_TIME_FORMAT),
              time_end: moment().format(VTIGER_TIME_FORMAT),
            },
          }),
      },
    ];
  }
}
