const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\App.tsx";import React from "react";

import { BrowserRouter as ReactRouter } from "react-router-dom";

import { AppContext, } from "../context";
import { Router } from "./Router";
import { Layout } from "./Layout";
import { AppLoadingScreen } from "./AppLoadingScreen";
import { ConfigProvider } from "antd";
import deLocale from "antd/lib/locale/de_DE";





export function App({ app }) {
  return (
    React.createElement(AppContext.Provider, { value: app, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , React.createElement(ConfigProvider, { locale: deLocale, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , React.createElement(AppLoadingScreen, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          , React.createElement(ReactRouter, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
            , React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
              , React.createElement(Router, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
            )
          )
        )
      )
    )
  );
}
