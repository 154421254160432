const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\AppBanner.tsx";import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Alert } from "antd";

import { useApp } from "../context";

const Container = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;

  background-color: #fffbe6;
  border-bottom: 1px solid #ffe58f;
`;

const Content = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
`;

export const AppBanner = observer(function AppBanner() {
  const app = useApp();

  if (app.offline) {
    return (
      React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        , React.createElement(Content, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
          , React.createElement(Alert, {
            banner: true,
            message: "Die Anwendung ist offline oder hat Probleme mit dem Server zu kommunizieren. Daten können nicht synchronisiert oder geschrieben werden."                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          )
        )
      )
    );
  }

  return null;
});
