 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";
import * as CACHE from "idb-keyval";

import { UiService } from "./UiService";
import { NotificationService } from "./NotificationService";
import { ApiService } from "./ApiService";









import { DataType } from "./DataType";
import { createDataFormUrl } from "../helper/createDataFormUrl";
import { FIELDS_ANRUF, FIELDS_BESUCH } from "../const";

export class AppService {
   __init() {this.ui = new UiService(this)}
   __init2() {this.notifications = new NotificationService(this)}
   __init3() {this.api = new ApiService(this)}

   __init4() {this.loading = true}
   __init5() {this.offline = false}
   __init6() {this.error = false}

   __init7() {this.authentificated = false}
   __init8() {this.updateAvailable = false}

    __init9() {this.types = {
    Accounts: new DataType(this, {
      typeName: "Accounts",
      icon: "fa:building",
      getTitle: (account) => {
        return account.accountname;
      },
      getDescription: (account) => {
        return account.industry;
      },
      links: [
        {
          title: "Personen anzeigen",
          description: "Finde alle Personen in diesem Unternehmen",
          getUrl: (id) => {
            return `/data/list/Contacts/account_id/${id}`;
          },
        },
        {
          title: "Anruf anlegen",
          description: "Hier klicken, um einen neuen Anruf zu vermerken",
          getUrl: (id) =>
            createDataFormUrl({
              title: "Anruf anlegen",
              typeName: "Events",
              fieldNames: FIELDS_ANRUF,
              defaultData: {
                activitytype: "Call",
                assigned_user_id: this.api.userId ,
                duration_hours: "1",
                parent_id: id,
              },
            }),
        },
        {
          title: "Kundenbesuch anlegen",
          description: "Hier klicken, um einen neuen Kundenbesuch zu vermerken",
          getUrl: (id) =>
            createDataFormUrl({
              title: "Kundenbesuch anlegen",
              typeName: "Events",
              fieldNames: FIELDS_BESUCH,
              defaultData: {
                activitytype: "Kundenbesuch",
                assigned_user_id: this.api.userId ,
                duration_hours: "1",
                parent_id: id,
              },
            }),
        },
      ],
    }),
    Contacts: new DataType(this, {
      typeName: "Contacts",
      icon: "fa:user",
      getTitle: (contact) => {
        return `${contact.salutationtype} ${contact.firstname} ${contact.lastname}`;
      },
      getDescription: (contact) => {
        const accountType = this.getType("Accounts");
        const company = _optionalChain([accountType, 'optionalAccess', _4 => _4.getEntity, 'call', _5 => _5(contact.account_id)]);

        return `${_optionalChain([company, 'optionalAccess', _6 => _6.accountname])}, ${contact.cf_918}`;
      },
      links: [],
    }),
    Leads: new DataType(this, {
      typeName: "Leads",
      icon: "fa:address-card",
      getTitle: (lead) => {
        return lead.firstname + " " + lead.lastname;
      },
      getDescription: (lead) => {
        return lead.company;
      },
      links: [
        {
          title: "Anruf anlegen",
          description: "Hier klicken, um einen neuen Anruf zu vermerken",
          getUrl: (id) =>
            createDataFormUrl({
              title: "Anruf anlegen",
              typeName: "Events",
              fieldNames: FIELDS_ANRUF,
              defaultData: {
                activitytype: "Call",
                assigned_user_id: this.api.userId ,
                duration_hours: "1",
                parent_id: id,
              },
            }),
        },
        {
          title: "Kundenbesuch anlegen",
          description: "Hier klicken, um einen neuen Kundenbesuch zu vermerken",
          getUrl: (id) =>
            createDataFormUrl({
              title: "Kundenbesuch anlegen",
              typeName: "Events",
              fieldNames: FIELDS_BESUCH,
              defaultData: {
                activitytype: "Kundenbesuch",
                assigned_user_id: this.api.userId ,
                duration_hours: "1",
                parent_id: id,
              },
            }),
        },
      ],
    }),
    // Calendar: new DataType<CalendarInterface>(this, {
    //   typeName: "Calendar",
    //   // icon: "fa:chart-network",
    //   icon: "fa:calendar-alt",
    //   getTitle: (calendar) => {
    //     return calendar.subject;
    //   },
    //   getDescription: (calendar) => {
    //     return calendar.date_start + " " + calendar.time_start;
    //   },
    //   links: [],
    // }),
    Events: new DataType(this, {
      typeName: "Events",
      icon: "fa:chart-network",
      getTitle: (calendar) => {
        return calendar.subject;
      },
      getDescription: (calendar) => {
        return calendar.date_start + " " + calendar.time_start;
      },
      links: [],
    }),
    Users: new DataType(this, {
      typeName: "Users",
      icon: "fa:smile-wink",
      getTitle: (contact) => {
        return `${contact.first_name} ${contact.last_name}`;
      },
      getDescription: (contact) => {
        return contact.user_name;
      },
      links: [
        {
          title: "Accounts anzeigen",
          description: "Accounts anzeigen, die diesem Nutzer zugewiesen sind",
          getUrl: (id) => {
            return `/data/list/Accounts/assigned_user_id/${id}`;
          },
        },
        {
          title: "Contacts anzeigen",
          description: "Contacts anzeigen, die diesem Nutzer zugewiesen sind",
          getUrl: (id) => {
            return `/data/list/Contacts/assigned_user_id/${id}`;
          },
        },
        {
          title: "Leads anzeigen",
          description: "Leads anzeigen, die diesem Nutzer zugewiesen sind",
          getUrl: (id) => {
            return `/data/list/Leads/assigned_user_id/${id}`;
          },
        },
        {
          title: "Kalender anzeigen",
          description: "Kalender anzeigen, die diesem Nutzer zugewiesen sind",
          getUrl: (id) => {
            return `/data/list/Calendar/assigned_user_id/${id}`;
          },
        },
      ],
    }),
  }}

  constructor() {;AppService.prototype.__init.call(this);AppService.prototype.__init2.call(this);AppService.prototype.__init3.call(this);AppService.prototype.__init4.call(this);AppService.prototype.__init5.call(this);AppService.prototype.__init6.call(this);AppService.prototype.__init7.call(this);AppService.prototype.__init8.call(this);AppService.prototype.__init9.call(this);
    makeAutoObservable(this);

    this.init();
  }

   async init() {
    console.log("app.init()");

    try {
      this.loading = true;
      this.authentificated = !!(this.api.username && this.api.accessToken);

      if (!this.authentificated) {
        this.api.username = null;
        this.api.accessToken = null;
        this.loading = false;
        return;
      }

      await this.api.getSession().catch((error) => this.handleError(error));

      await Promise.all(Object.values(this.types).map((type) => type.init()));

      // setInterval(() => {
      //   console.log("ping");
      //   this.api.listtypes().catch((error) => this.handleError(error));
      // }, 60 * 1000);

      console.log("init done");

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.handleError(error);
    }
  }

   getType(typeName) {
    // @ts-ignore
    return this.types[typeName];
  }

   getTypeForEntity(id) {
    const [prefix] = id.split("x");

    // @ts-ignore
    return Object.values(this.types).find(
      (type) => _optionalChain([type, 'access', _7 => _7.getMeta, 'call', _8 => _8(), 'optionalAccess', _9 => _9.idPrefix]) === prefix
    );
  }

   async login(username, accessToken) {
    console.log("login", username, accessToken);

    this.api.username = username;
    this.api.accessToken = accessToken;

    try {
      await this.api.getSession();

      this.notifications.info("Erfolgreich eingeloggt");

      await this.init();
    } catch (error) {
      this.api.username = null;
      this.api.accessToken = null;

      if (_optionalChain([error, 'optionalAccess', _10 => _10.__api__error__code]) === "ACCESS_DENIED") {
        this.notifications.error("Benutzername oder Auth Token fehlerhaft");
        return;
      }

      if (_optionalChain([error, 'optionalAccess', _11 => _11.__api__error__code]) === "INVALID_USER_CREDENTIALS") {
        this.notifications.error("Benutzername oder Auth Token fehlerhaft");
        return;
      }

      this.handleError(error);
    }
  }

   async create(typeName, data) {
    try {
      const type = this.getType(typeName);

      if (!type) {
        throw new Error();
      }

      const entity = await this.api.create(typeName, data);
      const id = entity.id;

      type.upsertEntity(id, entity);

      this.notifications.info("Objekt wurde erfolgreich erzeugt");

      return id;
    } catch (error) {
      this.notifications.error("Fehler beim erzeugen des Objekts");
      return null;
    }
  }

   async update(typeName, data) {
    try {
      const type = this.getType(typeName);

      if (!type) {
        throw new Error();
      }

      const entity = await this.api.update(data);
      const id = entity.id;

      type.upsertEntity(id, entity);

      this.notifications.info("Objekt wurde erfolgreich geändert");

      return id;
    } catch (error) {
      this.notifications.error("Fehler beim Ändern des Objekts");
      return null;
    }
  }

   async clearCache(hard = false) {
    await CACHE.clear();

    if (hard) {
      if ("serviceWorker" in navigator) {
        const reg = await navigator.serviceWorker.getRegistration();

        if (reg) {
          await reg.unregister();
        }
      }

      if (window.caches) {
        const caches = window.caches;
        const cacheKeys = await caches.keys();

        for (const cacheKey of cacheKeys) {
          await caches.delete(cacheKey);
        }
      }

      return window.location.reload();
    }

    await this.init();
  }

   async logout() {
    // this.username = null;
    // this.accessToken = null;

    window.localStorage.clear();

    await CACHE.clear();

    await this.init();

    this.notifications.info("Erfolgreich ausgeloggt");
  }

   handleError(error) {
    if (error.__network__error) {
      this.offline = true;
      return;
    }

    //test

    if (error.__api__error) {
      this.offline = true;
      return;
    }

    this.error = true;
  }
}
