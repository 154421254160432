const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\LogoutRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { Navigate } from "react-router-dom";

export const LogoutRoute = observer(function LogoutRoute() {
  const app = useApp();

  React.useEffect(() => {
    app.logout();
  }, []);

  if (!app.authentificated) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Berechtigungs Fehler" ,
        message: "Du bist nicht eingeloggt"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      )
    );
  }

  return React.createElement(Navigate, { to: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} );
});
