const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\Navigation.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Divider, Drawer, Menu } from "antd";
import { Icon } from "@opendash/icons";

import { useApp } from "../context";

export const Navigation = observer(function Navigation() {
  const app = useApp();
  const navigate = useNavigate();

  if (!app.authentificated) {
    return null;
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement('a', {
        href: "#",
        onClick: (e) => {
          e.preventDefault();

          app.ui.menu = true;
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      
        , React.createElement(Icon, { icon: "fa:bars", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
      )

      , React.createElement(Drawer, {
        placement: "left",
        closable: true,
        onClose: () => {
          app.ui.menu = false;
        },
        visible: app.ui.menu,
        width: Math.min(window.innerWidth * 0.8, 400),
        bodyStyle: { padding: 0, paddingTop: 40 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      
        , React.createElement(Menu, { mode: "inline", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          , Object.entries(app.types).map(([typeName, type]) => (
            React.createElement(Menu.Item, {
              key: typeName,
              icon: React.createElement(Icon, { icon: type.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} ),
              children: _optionalChain([type, 'access', _ => _.getMeta, 'call', _2 => _2(), 'optionalAccess', _3 => _3.label]),
              onClick: () => {
                app.ui.menu = false;
                navigate(`/data/list/${typeName}`);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
            )
          ))
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
          , app.ui.menuItems.map((item) => (
            React.createElement(Menu.Item, {
              key: item.label,
              icon: React.createElement(Icon, { icon: item.icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} ),
              children: item.label,
              onClick: () => {
                app.ui.menu = false;
                navigate(item.getUrl());
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
            )
          ))
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
          , React.createElement(Menu.Item, {
            icon: React.createElement(Icon, { icon: "fa:sync", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} ),
            children: "Cache leeren" ,
            onClick: () => {
              app.clearCache();
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
          )
          , React.createElement(Menu.Item, {
            icon: React.createElement(Icon, { icon: "fa:tools", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} ),
            children: "Update erzwingen" ,
            onClick: () => {
              app.clearCache(true);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
          )
          , React.createElement(Menu.Item, {
            icon: React.createElement(Icon, { icon: "fa:sign-out", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} ),
            children: "Abmelden",
            onClick: () => {
              navigate(`/logout`);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          )
        )
      )
    )
  );
});
