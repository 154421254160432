const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\DataForm.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";


import { Button, Form } from "antd";
import { useApp } from "../context";
import { DataFormInput } from "./DataFormInput";
import { useNavigate } from "react-router-dom";

export const DataForm = observer(function DataForm({
  type,
  id,
  fields,
  data,
}




) {
  const app = useApp();
  const navigate = useNavigate();

  const [change, setChange] = React.useState





({});

  React.useEffect(() => {
    setChange(
      Object.fromEntries([
        ...Object.entries(data).map(([key, value]) => [
          key,
          { value, status: undefined, message: undefined },
        ]),
        ...fields.map((field) => [
          field.name,
          {
            value:
              data[field.name] ||
              field.default ||
              // @ts-ignore
              _optionalChain([field, 'access', _ => _.type, 'optionalAccess', _2 => _2.defaultValue]) ||
              "",
            status: undefined,
            message: undefined,
          },
        ]),
      ])
    );
  }, [type, id, data]);

  return (
    React.createElement(Form, {
      layout: "vertical",
      onSubmitCapture: () => {
        const data = Object.fromEntries(
          Object.entries(change).map(([key, { value }]) => [key, value])
        );

        if (!id) {
          app.create(type, data).then((newId) => {
            if (newId) {
              navigate(`/data/view/${type}/${newId}`);
            }
          });
        } else {
          app.update(type, data);
        }
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
    
      /* <pre>{JSON.stringify(change, null, 2)}</pre> */

      , fields.map((field) => {
        return (
          React.createElement(Form.Item, {
            key: field.name,
            // name={field.name}
            label: field.label,
            required: field.mandatory,
            validateStatus: _optionalChain([change, 'access', _3 => _3[field.name], 'optionalAccess', _4 => _4.status]),
            help: _optionalChain([change, 'access', _5 => _5[field.name], 'optionalAccess', _6 => _6.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          
            , React.createElement(DataFormInput, {
              field: field,
              value: _nullishCoalesce(_optionalChain([change, 'access', _7 => _7[field.name], 'optionalAccess', _8 => _8.value]), () => ( data[field.name])),
              setValue: (value) => {
                setChange({
                  ...change,
                  [field.name]: {
                    value,
                    status: undefined,
                    message: undefined,
                  },
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            )
          )
        );
      })

      , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
        , React.createElement(Button, { type: "primary", htmlType: "submit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}, "Speichern"

        )
      )
    )
  );
});
