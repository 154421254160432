export const VTIGER_DATE_FORMAT = "YYYY-MM-DD";
export const VTIGER_TIME_FORMAT = "HH:mm:ss";

export const FIELDS_ANRUF = [
  "subject",
  "assigned_user_id",
  "date_start",
  "time_start",
  "due_date",
  "time_end",
  "parent_id",
  "eventstatus",
  "activitytype",
  "cf_938",
];

export const FIELDS_BESUCH = [
  "subject",
  "assigned_user_id",
  "date_start",
  "time_start",
  "due_date",
  "time_end",
  "parent_id",
  "eventstatus",
  "activitytype",
  "cf_936",
  "cf_938",
  "cf_940",
  "cf_1105",
  "cf_944",
];
