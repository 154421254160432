 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";
import * as CACHE from "idb-keyval";
















export class DataType {
  

  

   __init() {this.state = null}

   __init2() {this.options = []}

  constructor(app, config) {;DataType.prototype.__init.call(this);DataType.prototype.__init2.call(this);
    this.app = app;
    this.config = config;

    makeAutoObservable(this);
  }

  get typeName() {
    return this.config.typeName;
  }

  get icon() {
    return this.config.icon;
  }

  get getTitle() {
    return this.config.getTitle;
  }

  get getDescription() {
    return this.config.getDescription;
  }

  get links() {
    return this.config.links;
  }

  get count() {
    return Object.keys(_optionalChain([this, 'access', _ => _.state, 'optionalAccess', _2 => _2.entities]) || {}).length;
  }

   async init() {
    try {
      await this.loadCache();

      await this.sync();

      this.createOptions();
    } catch (error) {}
  }

   getMeta() {
    return _optionalChain([this, 'access', _3 => _3.state, 'optionalAccess', _4 => _4.meta]);
  }

   getEntity(id) {
    return _optionalChain([this, 'access', _5 => _5.state, 'optionalAccess', _6 => _6.entities, 'access', _7 => _7[id]]);
  }

   async upsertEntity(id, value) {
    if (!this.state) {
      return false;
    }

    this.state.entities[id] = value;

    await this.saveCache();

    return true;
  }

   listEntities() {
    return Object.values(_optionalChain([this, 'access', _8 => _8.state, 'optionalAccess', _9 => _9.entities]) || {});
  }

   createOptions() {
    if (this.state) {
      this.options = Object.entries(this.state.entities).map(
        ([value, entity]) => ({
          value,
          label: this.getTitle(entity),
        })
      );
    }
  }

   async sync() {
    await this.app.api.getSession();

    if (!this.state) {
      await this.fullSync();

      return;
    }

    const meta = await this.fetchMeta();

    this.state.meta = meta;

    const sync = await this.app.api.sync(this.typeName, this.state.lastSync);

    if (sync.more) {
      await this.fullSync();

      return;
    }

    Object.assign(
      this.state.entities,
      Object.fromEntries(sync.updated.map((entity) => [entity.id, entity])),
      Object.fromEntries(sync.deleted.map((id) => [id, undefined]))
    );

    this.state.lastSync = Math.floor(Date.now() / 1000);

    await this.saveCache();
  }

   async fullSync() {
    const meta = await this.fetchMeta();

    const entities = {};

    if (meta.retrieveable) {
      let page = 0;

      while (true) {
        const response = await this.app.api.queryType(this.typeName, page);

        for (const entity of response) {
          entities[entity.id] = entity;
        }

        if (response.length === 100) {
          page += 1;
        } else {
          break;
        }
      }
    }

    this.state = {
      lastSync: Math.floor(Date.now() / 1000),
      entities,
      meta,
    };

    await this.saveCache();
  }

   async fetchMeta() {
    const meta = await this.app.api.describe(this.typeName);

    const { fields, blocks } = await this.app.api.fields(this.typeName);

    const fieldsMap = Object.fromEntries(
      fields.map((field) => [field.name, field])
    );

    meta.blocks = blocks;
    meta.fields = Object.fromEntries(
      meta.fields.map((field) => [
        field.name,
        {
          ...field,
          ...(fieldsMap[field.name] || {}),
        },
      ])
    );

    return meta;
  }

   get cacheKey() {
    return `pollrich/state/${this.config.typeName}`;
  }

   async loadCache() {
    try {
      // @ts-expect-error
      this.state = JSON.parse(await CACHE.get(this.cacheKey));
    } catch (error) {
      this.state = null;
    }
  }

   async saveCache() {
    if (this.state) {
      await CACHE.set(this.cacheKey, JSON.stringify(this.state));
    } else {
      await CACHE.del(this.cacheKey);
    }
  }
}
