const _jsxFileName = "C:\\Users\\sz\\Code\\projects\\pollrich-app\\app\\js\\components\\LoginRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input } from "antd";

export const LoginRoute = observer(function LoginRoute() {
  const app = useApp();
  const navigate = useNavigate();

  if (app.authentificated) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Bereits Eingeloggt" ,
        message: "...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      )
    );
  }

  return (
    React.createElement(Form, {
      name: "auth",
      layout: "vertical",
      initialValues: { remember: true },
      onFinish: ({ username, password }) => {
        app.login(username, password).then(
          () => {
            navigate("/home");
          },
          () => {
            // ...
          }
        );
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    
      , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , React.createElement(Alert, {
          type: "info",
          message: 
            React.createElement(React.Fragment, null
              , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, "Das Auth Token finden Sie im CRM unter "        )
              , React.createElement('a', {
                href: "http://crm.pollrich.local/vtigercrm/index.php?module=Users&view=PreferenceDetail&parent=Settings&record=1",
                title: "Einstellungen von vTiger"  ,
                target: "_blank",
                rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
              , "Einstellungen"

              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        )
      )

      , React.createElement(Form.Item, {
        label: "Benutzername",
        name: "username",
        rules: [{ required: true, message: "Bitte Benutzernamen angeben." }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
      
        , React.createElement(Input, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 62}} )
      )

      , React.createElement(Form.Item, {
        label: "Access Token" ,
        name: "password",
        rules: [{ required: true, message: "Bitte Access Token angeben." }], __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      
        , React.createElement(Input.Password, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} )
      )

      , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        , React.createElement(Button, { type: "primary", htmlType: "submit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}, "Anmelden"

        )
      )
    )
  );
});
